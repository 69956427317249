import React from "react";
import { Link } from "gatsby";
//import style from "./index.scss";
import { kebabCase } from 'lodash';

export default class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        id: postEdge.node.fields.slug,
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        draft: postEdge.node.fields.draft,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }
  render() {
    const postList = this.getPostList();
    return (
      <div className='post-listing'>
        {
        postList.map(post =>
          <div className='post' key={ post.id }>
            <time>{post.date}</time>
            <Link className='title' to={post.path}>
              <h1>{post.title}</h1>
            </Link>
            <section className='excerpt'>
              {post.excerpt}
            </section>
            { post.draft ? (<span className='draft-flag'/>) : null }
            {post.tags != null ? (
              <div className='tags'>
                { post.tags.map(tag => <span key={`/tag/${kebabCase(tag)}`}><Link to={ `/tag/${kebabCase(tag)}/` }>{ tag }</Link> </span>) }
              </div>
            ) : null}
            <hr />
          </div>
        )}
      </div>
    );
  }
}
